import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import ERC20 from '../abis/erc20-token';

export function getNecoBNBLPAddress(chainId: number): string | undefined {
  return ERC20.necoBNBAddress.get(chainId);
}

export function getNecoBNBContract(web3: Web3 | null, chainId: number): Contract | null {
  const address = ERC20.necoBNBAddress.get(chainId);
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(ERC20.ABI, address);
}

export function getERC20Contract(web3: Web3 | null, address: string): Contract | null {
  if (web3 === null) {
    return null;
  }
  return new web3.eth.Contract(ERC20.ABI, address);
}

/**
 * 得到BUSD的合约地址
 * @param web3
 * @param address
 * @param account
 */
export async function getBalance(
  web3: Web3, address: string, account: string,
): Promise<string> {
  const contract = getERC20Contract(web3, address);
  if (contract === null) {
    return '0';
  }
  return contract.methods.balanceOf(account).call();
}

/**
 * 获取当前账户授权的BUSD余额
 * @param web3
 * @param address
 * @param owner
 * @param spender
 */
export async function getAllowanceBalance(
  web3: Web3, address: string, owner: string, spender: string,
): Promise<string> {
  const contract = getERC20Contract(web3, address);
  if (contract === null) {
    return '';
  }
  return contract.methods.allowance(owner, spender).call();
}

/**
 * 进行合约的approve
 * @param web3
 * @param address
 * @param from
 * @param spender
 * @param amount
 */
export async function approveToken(
  web3: Web3,
  address: string,
  from: string,
  spender: string,
  amount: string,
): Promise<boolean> {
  const contract = getERC20Contract(web3, address);
  if (contract === null) {
    return false;
  }
  try {
    await contract.methods.approve(spender, amount).send({ from });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}
